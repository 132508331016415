/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import getApplicationRoutes from '../core/routing'
import { GetServerSideProps } from 'next'
import renders from '../core/renders'
import { parsePageDefinition } from '../core/render-connector'
import { getDomainLocale, getPathInfo } from '../utils/VL'
import { ServerPageProps } from '../core/interfaces'
import { checkAASA } from '../core/tools/url'
import {
  getSanitizedQuery,
  usingDeeplink,
  usingLocale,
  usingTrackingData,
} from '../core/tools/serverSide'
import { notFound } from '../core/errorPageRedirects'

const DynamicPage = (props: ServerPageProps) => {
  if (!props.render || !renders[props.render]) {
    return <h1>Page not resolved!</h1>
  }

  const { Render } = renders[props.render]
  return <Render {...props} />
}

export const getServerSideProps: GetServerSideProps<ServerPageProps> = async (
  context
) => {
  const { isSupported, locale, country } = getDomainLocale(context)

  if (isSupported) {
    usingLocale(context, locale)
    const { ajsUserId, ajsAnonymousId } = usingTrackingData(context)
    const { pathkey, path, pathname } = getPathInfo(context.resolvedUrl)
    const hasAASA = checkAASA({ res: context.res, country, path })
    if (hasAASA) {
      return {
        props: {
          ajsAnonymousId,
          ajsUserId,
          name: 'aasa',
          pathname,
          locale,
          query: {},
        },
      }
    }

    const routes = getApplicationRoutes()
    let routeConfig = routes.statics[path]
    let params = {}

    if (!routeConfig) {
      const dynamics = routes.dynamics[pathkey] || routes.dynamics._root || {}
      Object.keys(dynamics).some((key) => {
        const route = dynamics[key]
        const pathdata = route.process(path)

        if (pathdata) {
          routeConfig = route
          const { id: entityId, ...others } = pathdata.params || {}
          params = {
            ...others,
            ...(entityId ? { entityId } : {}),
          }
        }
        return !!pathdata
      })
    }
    if (routeConfig) {
      const {
        source: sourceConfig,
        namespace,
        segmentPageName = null,
        scripts = [],
        dependencies = null,
        options: configurations = {},
      } = routeConfig
      const { applicationId, accountId, storeId } = usingDeeplink(context)
      const { key: name, render } = parsePageDefinition(sourceConfig)
      const customRender = renders[render]
      const { slug = '/', ...query } = getSanitizedQuery(context)

      const pageProps: ServerPageProps = {
        name,
        trackName: segmentPageName,
        render,
        namespace,
        dependencies,
        scripts: scripts.map((src) => ({
          src,
          strategy: 'beforeInteractive',
        })), // TODO: Need to support scripts into VS Configuration
        configurations,
        pathname,
        locale,
        query: { ...query, ...params },
        applicationId: applicationId.toLowerCase(),
        accountId,
        storeId,
        ajsAnonymousId,
        ajsUserId,
      }

      console.info('Using Dynamic Routing System:', slug, pageProps)

      if (customRender && customRender.getServerSideProps) {
        const serverProps = await customRender.getServerSideProps(
          context,
          pageProps
        )

        if (serverProps['notFound']) {
          console.error('Error getting template')
          return notFound
        }
        return serverProps
      }

      return { props: pageProps }
    }
  }

  console.error('Page not resolved!', {
    resolvedUrl: context.resolvedUrl,
    isSupported,
  })
  return notFound
}

export default DynamicPage
